// ServicesPage.js
import React from 'react';
import hero from './img/blackandwhiteno-1.jpg'

const ServicesPage = () => (
    <div className="content">
        <img className="hero-img animate__animated animate__fadeIn" src={hero} alt={"Black and White Number 1"} />

        <h1>Services</h1>
        <p>Reach out for a <a href="mailto:montecristoph.com">free consultation</a> on any of Lord Toph's services, including Artist Development and Voice Promotion.</p>
        <h2>Artist Development</h2>
        <p>Developing confidence, performance presence and personal branding for entertainers. In the entertainment world, image is everything, and presence is absolute. You’ll need more than social media to define you and a whole lot more than just talent to break through.</p>
        <h2>Voice Promotion</h2>
        <p>Need a distinctive voice to promote your event, products or services? A warm, welcoming voice, is exactly what will attract potential customers to your offer and subliminally convince them to become your return customers also. Existing customers will feel more assured of your services as well! Lord Toph Voice Services provides a certain rich, resonance which will audibly connect with your visitors to enhance the process of converting them into lasting customers, in a very inviting way.</p>
        
           <p>Offering a 5% to 10% referral fee on all referred individuals who become clients</p>

    </div>
);

export default ServicesPage;
