// AboutRelatedActsPage.js
import React from 'react';

const AboutRelatedActsPage = () => (
    <div className="content relatedacts">
        <h1>Lord Toph Related Acts and Talent</h1>
        <p>The following music artists have performed, written, or collaborated with Lord Toph, or have been developed and/or produced by Lord Toph. Each artist has contributed their unique talents and creative vision, enriching the musical tapestry that defines Lord Toph's expansive career. Through these collaborations, Lord Toph has forged enduring artistic relationships that have resulted in a diverse and dynamic body of work, spanning multiple genres and resonating with audiences around the world.</p>

        <div className="item">Damen Samuel (Folk/Acoustic/Soul)</div>
        <div className="item">Ebony Anne Isaac (Adult Contemporary/R&B/Pop)</div>
        <div className="item">SnowFlake Black (Hip-Hop/Rap)</div>
        <div className="item">The Tony Mazza Project (Rock/Blues)</div>
        <div className="item">Foxy Foxes (Pop/Dance)</div>
        <div className="item">Cris Vela (R&B/Soul)</div>
        <div className="item">Peek-a-Boo & the Funcats (Brit Pop/’80s Pop)</div>
        <div className="item">TriQi Davis (Pop/Soul)</div>
        <div className="item">Joey Coca (Rock/Blues/Soul)</div>
        <div className="item">Luscious Spiller (Soul/Blues/Rock)</div>
        <div className="item">Katie Redman (Adult Contemporary/Soft Rock/Gospel)</div>

    </div>
);

export default AboutRelatedActsPage;
