// AboutPage.js
import React from 'react';
import Symbol from "./img/Lord_Toph_logo_symbol.png";
import Hero from "./img/Lord_Toph_about.png";
import sign from './img/Lord_Toph_signature.png';
const AboutPage = () => (
    <div className="content">
        <img className="hero-img animate__animated animate__fadeIn" src={Hero} alt={"Lord Toph Star Man"}/>
        <h1>About Lord Toph</h1>
        <div className={"contentauth"}><img src={Symbol} className="symbol" alt={"Lord Toph symbol"}/>
        <p className={"home-text"}>Welcome to the world of Lord Toph, a realm where creativity knows no boundaries and artistry reigns supreme. With a musical legacy spanning across genres and a boundless passion for expression, Lord Toph stands as a beacon of innovation in the realm of contemporary arts.</p>
                <p>&nbsp;</p>
                <h2>The Maestro of Song & Melody</h2>
                <p>With over 1,500 songs to his name, Lord Toph's musical journey is a tapestry woven with threads of Pop, Rock, Gothic Rock, Soul, R&B, Funk, Alternative, Instrumental and Ambient influences. Each composition is a testament to his unwavering dedication to sonic exploration, blending rich harmonies with evocative lyrics to create an auditory experience that transcends the ordinary.</p>
                <h2>A Renaissance Soul</h2>

                <p>Beyond his mastery of sound, Lord Toph is a true polymath, with talents that extend far beyond the realm of music. As a visual artist, his creations captivate the eye and stir the soul, offering glimpses into realms both fantastical and profound. His brush strokes dance across canvases, breathing life into landscapes of imagination and emotion.</p>

                <h2>A Wordsmith Extraordinaire</h2>

                <p>But Lord Toph's artistic canvas knows no bounds, as his literary prowess shines brightly in the realm of words. With pen in hand, he weaves tales of love, loss, triumph and transcendence, inviting readers on journeys of introspection and enlightenment.</p>

                <h2>A Visionary's Odyssey</h2>

                <p>Lord Toph's journey is not merely one of creation, but of transformation. With each brushstroke, each chord, and each word, he invites us to embark on a journey of self-discovery, daring us to embrace the boundless possibilities of our own creativity.</p>

        <h2>Join the Journey</h2>

        <p>Join us as we delve into the depths of Lord Toph's imagination, exploring the myriad facets of his artistry and celebrating the indomitable spirit of creativity that defines his essence. Together, let us embark on a voyage of discovery, where music, art and literature converge to illuminate the human experience in all its wondrous complexity.</p>

        <h2>A Perspective from Lord Toph</h2>
                <p>“How I feel now, is entirely different to how I shall feel a week from now.  Too many factors already have determined this.  I am here… And yov are there, yet svch a vast amovnt of space, lies in between.  By calling ovt lovdly, perhaps yov have sensed its vastness, in yovr dreams. </p>
                <p>This is exactly what I, as well as we… A chosen few are doing to bring abovt change, to bring abovt newness, innovation.  Anyone can easily and hastily inclvde themselves by ridicvlovsly saying, ‘Me too!’ withovt ever conceptvalizing or mentally and physically bringing something into frvition.  This sadly, is a foolish folly that occvrs far too often, among the ones who envy… The ones who secretly mimic and wish they were jvst like the one they privately, so emphatically adore.  This is no mere notion, becavse I myself, have dealt with this for years… more than yov can imagine.</p>
                <p>Dreamers can either be beavtifvl or imbecilic.  Yet the dreamer who has the ability to imagine then, at will, to extract dreams from some other realm and intrinsically bring them into frvition, is often times considered a sage, an icon or even a genivs.  With this being said, keep this in mind.  Anyone can start a trend… yet only a chosen few, can create worlds.”
                </p>
                <div><img src={sign} alt={"Lord Toph's Signature"} /></div>
        </div>
    </div>
);


export default AboutPage;