import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Logo from "./img/Lord_Toph_logo1.png";

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false);
    const [aboutDropdownActive, setAboutDropdownActive] = useState(false);
    const [shopDropdownActive, setShopDropdownActive] = useState(false);

    let navigate = useNavigate();

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    const toggleDropdown = (dropdown) => {
        if (dropdown === 'about') {
            setAboutDropdownActive(!aboutDropdownActive);
            setDropdownActive(false);
            setShopDropdownActive(false);
        } else if (dropdown === 'resources') {
            setDropdownActive(!dropdownActive);
            setAboutDropdownActive(false);
            setShopDropdownActive(false);
        } else if (dropdown === 'shop') {
            setShopDropdownActive(!shopDropdownActive);
            setAboutDropdownActive(false);
            setDropdownActive(false);
        }
    };

    const handleNavigation = (path) => {
        navigate(path);
        setMenuActive(false);
        setDropdownActive(false);
        setAboutDropdownActive(false);
        setShopDropdownActive(false);
    };

    return (
        <section className="navbar">
            <div className="menu-icon" onClick={toggleMenu}>
                {menuActive ? (
                    <span>X</span>
                ) : (
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                )}
            </div>
            <div className={`menu ${menuActive ? 'active' : ''}`}>
                <ul className={`nav-list ${menuActive ? 'active' : ''}`}>
                    <li>
                        <a href="/" onClick={() => handleNavigation('/')}>
                            <img src={Logo} className="logo" alt={"Lord Toph"} />
                        </a>
                    </li>
                    <li className={"nav-item"}>
                        <a href="/" onClick={() => handleNavigation('/')}>Home</a>
                    </li>
                    <li className="nav-item dropdown">
                        <button className="dropbtn" onClick={() => toggleDropdown('about')}>
                            About
                            <span className={`twisty ${aboutDropdownActive ? 'open' : 'closed'}`}>▼</span>
                        </button>
                        {aboutDropdownActive && (
                            <div className="dropdown-content">
                                <Link to="about-lord-toph" onClick={() => handleNavigation('about-lord-toph')}>Introduction</Link>
                                <Link to="about-lord-toph/biography" onClick={() => handleNavigation('about-lord-toph/biography')}>Bio & Creative Repertoire</Link>
                                <Link to="about-lord-toph/discography" onClick={() => handleNavigation('about-lord-toph/discography')}>Discography</Link>
                                <Link to="about-lord-toph/literary-catalog" onClick={() => handleNavigation('about-lord-toph/literary-catalog')}>Literary Catalog</Link>
                                <Link to="about-lord-toph/related-acts" onClick={() => handleNavigation('about-lord-toph/related-acts')}>Related Acts & Talent</Link>
                            </div>
                        )}
                    </li>
                    <li className="nav-item dropdown">
                        <button className="dropbtn" onClick={() => toggleDropdown('resources')}>
                            Resources
                            <span className={`twisty ${dropdownActive ? 'open' : 'closed'}`}>▼</span>
                        </button>
                        {dropdownActive && (
                            <div className="dropdown-content">
                                <Link to="blog" onClick={() => handleNavigation('blog')}>Blog</Link>
                                <Link to="services" onClick={() => handleNavigation('services')}>Services</Link>
                                <Link to="tandr" onClick={() => handleNavigation('tandr')}>T&R</Link>
                            </div>
                        )}
                    </li>
                    <li className="nav-item dropdown">
                        <button className="dropbtn" onClick={() => toggleDropdown('shop')}>
                            Shop
                            <span className={`twisty ${shopDropdownActive ? 'open' : 'closed'}`}>▼</span>
                        </button>
                        {shopDropdownActive && (
                            <div className="dropdown-content">
                                <Link to="music" onClick={() => handleNavigation('music')}>Music</Link>
                                <Link to="art" onClick={() => handleNavigation('art')}>Art</Link>
                                <Link to="literature" onClick={() => handleNavigation('literature')}>Literature</Link>
                            </div>
                        )}
                    </li>
                    <li className={"nav-item contact-us"}>
                        <a href="contact-us" onClick={() => handleNavigation('/')}>Contact</a>
                    </li>
                    <li className={"nav-item"}>
                        <Link to="psychedelic-dream" onClick={() => handleNavigation('psychedelic-dream')}>
                            <div className={"psychedelic"}>Psychedelic Dream</div>
                        </Link>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Navbar;
